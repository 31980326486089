<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AcctEditMain from "@/views/SystemManagement/AcctManage/TabChild/AcctEditMain";
export default {
  name: 'AcctEdit',
  components: {
    childTab1: AcctEditMain,
  },
  data() {
    return {
      activeName: "first",
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">

</style>