<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <editHeader
      :statusShow='false'
      :isShow="isShow"
      :btn="btn"
      :strForm="acctForm"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit('acctForm')"/> 
    <el-form ref="acctForm" :model="acctForm" :rules="rules" :disabled="isShow" label-width="120px" size="mini">
      <el-row>
        <div class="vd_tip"><i class="el-icon-info"></i>友情提示：新增的账套编号和绑定公司后不能更改</div>
      </el-row>
      <el-row>
        <el-col :md="6">
          <el-form-item label="账套编号" prop="acct_no">
            <el-input v-model="acctForm.acct_no" maxlength="5" disabled @input ="acctForm.acct_no = helper.keepEnglish(acctForm.acct_no)" show-word-limit placeholder="请填写账套编号（仅支持英文）"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6" :offset="2" >
          <el-form-item label="账套简称" prop="acct_name">
            <el-input v-model="acctForm.acct_name" maxlength="6" show-word-limit placeholder="请填写账套简称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6" :offset="2"  >
          <el-form-item label="绑定公司" prop="cptt_id">
            <el-input v-model="acctForm.cptt_name" disabled maxlength="6" show-word-limit placeholder="请填写账套简称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="6">
          <el-form-item label="账套公司类型" prop="acct_type">
            <el-select v-model="acctForm.acct_type" placeholder="请选择账套公司类型"  clearable>
              <el-option label="一类" :value="1"></el-option>
              <el-option label="二类" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <!-- <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button> -->
      </div>
      <!-- <el-table ref="bank" :data="acctForm.acct_fctr_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="48" align="center">
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column >
          <template slot="header">
            <span class="vg_deep_red">旗下工厂编号</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_no'" :rules="[{required:true}]">
              <el-input v-model="acctForm.acct_fctr_list[scope.$index].acct_fctr_no" @input ="acctForm.acct_fctr_list[scope.$index].acct_fctr_no = helper.keepEngNum2(acctForm.acct_fctr_list[scope.$index].acct_fctr_no)" maxlength="10" show-word-limit placeholder="请填写工厂编号（必填）"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">旗下工厂名</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_name'" :rules="[{required:true}]">
              <el-input v-model="acctForm.acct_fctr_list[scope.$index].acct_fctr_name" maxlength="30" show-word-limit placeholder="请填写工厂名（必填）"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">旗下工厂类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_type'" :rules="[{required:true}]">
              <el-select v-model="acctForm.acct_fctr_list[scope.$index].acct_fctr_type" placeholder="请选择工厂类型"  clearable>
                <el-option label="加工厂" :value="1"></el-option>
                <el-option label="面料厂" :value="2"></el-option>
                <el-option label="配套厂" :value="3"></el-option>
                <el-option label="辅料厂" :value="4"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="旗下电话">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_phone'">
              <el-input maxlength="30" show-word-limit  v-model="acctForm.acct_fctr_list[scope.$index].acct_fctr_phone" placeholder="请填写电话号码"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="旗下地址">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_address'">
              <el-input  type="textarea" :rows="1"  v-model="acctForm.acct_fctr_list[scope.$index].acct_fctr_address" placeholder="请填写地址信息"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table> -->
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle"
import editHeader from "@/views/component/editHeader";

export default {
  name: 'AcctEditMain',
  components: {
    editHeader
  },
  data() {
    return {
      rules:{
        acct_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        acct_name:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        acct_type:[
          { required: true, trigger: 'change' , message: ' '},
        ],
        cptt_id:[
          { required: true, trigger: 'change' , message: ' '},
        ],
      },
      acctForm:{
        status:null,
        acct_id:'',
        acct_no:'',
        acct_name:'',
        acct_type:'',
        cptt_id:'',
        acct_fctr_list:[],
        key:0,
      },
      acct_fctr_list1:[],
      selectionsList: [],
      confirm: [],
      key: 0,
      isShow: true,
      isDisabled: true,
      btn:{},
      loadingFlag:true,
      cpttList:[]
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  methods:{
    initData(){
      this.getComptitle()
      this.getacctInfo()
    },
    //启用编辑
    openEdit(){
      this.isShow = false
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      })
      .catch(()=>{})
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
      .catch(()=>{})
    },
    // 保存
    saveInfo() {
      let delete_time = this.helper.toTimeVal();
      let acctForm = {};
      let acctLi = [];
      acctForm = Object.assign(acctForm,this.acctForm);
      acctLi = Object.assign(acctLi,this.acct_fctr_list1);
      let bbj = this.helper.deleteSaveBody(acctForm,acctLi,this.confirm,2);
      acctLi = acctLi.concat(bbj);
      acctForm.acct_fctr_list = acctLi;
      post(acctAPI.editAcct,acctForm)
      .then(res => {                                                     
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.loadingFlag = true
          this.isShow = true;
          this.initData()
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('acctForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
      .catch(()=>{})
    },
    // 获取公司
    getComptitle(){
      get(cpttAPI.getEmptyCpttsV1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttList = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //添加
    addRow() {
      let item = {
        k: this.key,
        acct_fctr_no: '',
        acct_fctr_name: '',
        acct_fctr_type: '',
        acct_fctr_phone:'',
        acct_fctr_address:'',
      }
      this.key = this.key +1;
      this.acctForm.acct_fctr_list.push(item);
      this.selectionsList = [];
    },
    //多选删除
    delBank() {
      for(let i = 0 ; i < this.selectionsList.length; i++) {
        this.acctForm.acct_fctr_list.forEach((item)=>{
          if(item.k === this.selectionsList[i].k) {
            let currentIndex = this.acctForm.acct_fctr_list.indexOf(item);
            this.acctForm.acct_fctr_list.splice(currentIndex,1);
          }
        });
      }
    },
   
    // 获取表单信息
    getacctInfo() {
      this.acctForm.acct_id = this.$route.query.form_id;
      get(acctAPI.getAcctById,{acct_id:this.acctForm.acct_id})
      .then(res=>{
        if (res.data.code === 0) {
          this.acctForm = res.data.data.form;
          this.acctForm.acct_fctr_list.forEach(item=>{
            item.k = this.key;
            this.key++;
          })
          this.acct_fctr_list1 = this.acctForm.acct_fctr_list;
          this.confirm = Object.assign(this.confirm,this.acctForm.acct_fctr_list)
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    }
  }
}
</script>

<style scoped lang="scss">
.vd_tip{
  font-size: 14px;
  color: #7a6464;
  margin-bottom: 10px;
  margin-top: -10px;
}
</style>
